import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Row, Small } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import { Link } from '../Link'
import { H1, P } from '../Typography'
import ReadMore from '../ReadMore'
import breakpoints from '../../util/style/breakpoints'

const ContentWrapper = styled.div`
  margin: 2.5rem 0;

  ${breakpoints.md`
      margin: 5rem 0;
  `};
`

const ImageTextBlock = ({
  title,
  subTitle,
  description,
  linkTo,
  image,
  reverse,
}) => (
  <Row className={reverse ? 'flex-row-reverse' : undefined}>
    {image && (
      <Col
        xs={12}
        md={7}
        style={{ minHeight: '226px' }}
        className={reverse ? 'offset-md-1' : undefined}
      >
        <GatsbyImage
          alt={title}
          image={image}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Col>
    )}
    <Col xs={12} md={4} className={!reverse ? 'offset-md-1' : undefined}>
      <ContentWrapper>
        {subTitle && (
          <div className="mb-3">
            <Small
              color="primary"
              className="text-uppercase font-weight-medium"
            >
              {subTitle}
            </Small>
          </div>
        )}
        {title && <H1 className="mb-3">{title}</H1>}
        {description && (
          <P color="#f5f5f5" className="mb-3">
            {description}
          </P>
        )}
        {linkTo && (
          <Link to={linkTo}>
            <ReadMore />
          </Link>
        )}
      </ContentWrapper>
    </Col>
  </Row>
)

export default injectIntl(ImageTextBlock)
