import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { getImage } from 'gatsby-plugin-image'
import { Container } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Section from '../components/UI/Section'
import GoogleReviewsBlock from '../components/Sections/GoogleReviewsBlock'
import PageHeader from '../components/PageHeader'
import ContactMe from '../components/ContactMe'
import ImageTextBlock from '../components/ImageTextBlock'

import breakpoints from '../util/style/breakpoints'

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}

  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`

export const servicesQuery = graphql`
  query {
    imageTenants: file(relativePath: { eq: "diensten/huurders.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 728
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageRealEstateAgents: file(relativePath: { eq: "diensten/makelaars.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 728
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageLandLords: file(relativePath: { eq: "diensten/verhuurders.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 728
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dienstenSummaryJson {
      en {
        title
        description
      }
      nl {
        title
        description
      }
    }
    verhuurdersSummaryJson {
      en {
        title
        description
      }
      nl {
        title
        description
      }
    }
    huurdersSummaryJson {
      en {
        title
        description
      }
      nl {
        title
        description
      }
    }
    makelaarsSummaryJson {
      en {
        title
        description
      }
      nl {
        title
        description
      }
    }
  }
`

class ServicesPage extends React.PureComponent {
  render() {
    const { intl, data } = this.props
    const imageTenants = getImage(data.imageTenants)
    const imageRealEstateAgents = getImage(data.imageRealEstateAgents)
    const imageLandLords = getImage(data.imageLandLords)
    const { title: titleDiensten , description: descriptionDiensten } = data.dienstenSummaryJson[intl.locale];
    const { title: titleVerhuurders , description: descriptionVerhuurders } = data.verhuurdersSummaryJson[intl.locale];
    const { title: titleHuurders , description: descriptionHuurders } = data.huurdersSummaryJson[intl.locale];
    const { title: titleMakelaars , description: descriptionMakelaars } = data.makelaarsSummaryJson[intl.locale];

    return (
      <>
        <Layout>
          <SEO title={`${intl.formatMessage({ id: 'site_title' })} - ${titleDiensten}`} />
          <TemplateWrapper>
            <PageHeader
              xlMargin
              title={titleDiensten}
              description={descriptionDiensten}
            />
            <Section>
              <Container>
                <ImageTextBlock
                  image={imageLandLords}
                  title={titleVerhuurders}
                  subTitle={intl.formatMessage({ id: 'LANDLORDS' })}
                  description={descriptionVerhuurders}
                  linkTo="/diensten-verhuurders"
                />
              </Container>
            </Section>
            <Section>
              <Container>
                <ImageTextBlock
                  image={imageTenants}
                  title={titleHuurders}
                  subTitle={intl.formatMessage({ id: 'TENANTS' })}
                  description={descriptionHuurders}
                  linkTo="/diensten-huurders"
                  reverse
                />
              </Container>
            </Section>
            <Section>
              <Container>
                <ImageTextBlock
                  image={imageRealEstateAgents}
                  title={titleMakelaars}
                  subTitle={intl.formatMessage({ id: 'REAL_ESTATE_AGENTS' })}
                  description={descriptionMakelaars}
                  linkTo="/diensten-makelaars"
                />
              </Container>
            </Section>
            <Section>
              <Container>
                <GoogleReviewsBlock />
              </Container>
            </Section>
            <ContactMe
              id={intl.formatMessage({ id: 'navigation.nav_3_id' })}
              header={intl.formatMessage({ id: 'contact.title' })}
              subTitle={intl.formatMessage({ id: 'contact.sub_title' })}
              contactUs={intl.formatMessage({ id: 'CONTACT_US' })}
            />
            <Footer withSkyline />
          </TemplateWrapper>
        </Layout>
      </>
    )
  }
}

export default injectIntl(ServicesPage)
